<template>
  <v-container
    id="panels"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >

        <base-material-card
          icon="mdi-clipboard-text"
          title="List Data Organisasi"
          class="px-5 py-3"
        >

          <v-row>
            <v-col>
              <v-text-field
                v-model="listQuery.search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col>
            <v-btn color="blue" @click="getList()">Search</v-btn>
            </v-col>
            <v-col class="text-right">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn color="red" v-on="on" @click="showAddForm()">
                      <v-icon>mdi-plus</v-icon>Tambah Data
                  </v-btn>
                </template>
                <span>Klik disini untuk Tambah data baru.</span>
              </v-tooltip>
              
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-data-table
              :headers="headers"
              :items="listData"
              :items-per-page="15"
              class="elevation-1"
              :server-items-length="totalData"
              :loading="listLoading"
              :options.sync="tableOpt"
            >
            <template v-slot:item.aksi="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn color="warning" v-on="on" fab x-small :to="'/pgw/hrorg/edit/'+item.id" >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Ubah</span>
              </v-tooltip>
 
              &nbsp;

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn color="red" v-on="on" fab x-small @click="delData(item.id)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Hapus Data</span>
              </v-tooltip>
              
            </template>
          </v-data-table>

        </base-material-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

    <v-dialog v-model="delConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin ingin meng-HAPUS data tersebut?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="delConfirm = false">Batal</v-btn>
            <v-btn color="blue darken-1" @click="doDeleteData()">Hapus</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </v-container>
</template>

<script>
  import moment from 'moment'
  import { getHROrganization, countHROrganization, deleteHROrganization } from '@/api/hrorganization'
  import { _GLOBAL } from '@/api/server'

  export default {
    name: 'Organization',
    data: () => ({
      tab: 0,
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Holding', value: 'holding', align: 'center', class:'subtitle-1' },
        { text: 'Nama Organisasi', value: 'structure_name', align: 'center', class:'subtitle-1' },
        { text: 'Level', value: 'level', align: 'center', class:'subtitle-1' },
        { text: 'Parent', value: 'sname', align: 'center', class:'subtitle-1' },
        { text: 'Kode Unit', value: 'unit_code', align: 'center', class:'subtitle-1' },
        { text: 'Numbering', value: 'numbering', align: 'center', class:'subtitle-1' },
        { text: 'isActive', value: 'isActive', align: 'center', class:'subtitle-1' },
        { text: 'Aksi', value: 'aksi', align: 'center', class:'subtitle-1'}
      ],
      isLoading: false,
      delConfirm: false,
      idDel: 0,
      listData: [],
      totalData: 0,
      listLoading: false,
      tableOpt: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: ''
      },
      dataListOrganization: [],
    }),
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).format('DD MMM YYYY HH:mm')
        return sdate
      }
    },

    created () {
      const vm = this

    },
    destroyed () {
    },
    mounted () {
      const vm = this
    },
    watch: {
      tableOpt: {
        handler () {
          this.getList()
        },
        deep: true,
      },
    },
    methods: {
      goFileLink (uri) {
        window.open(uri, '_blank');
      },
      showAddForm () {
        const vm = this
        vm.$router.push('/pgw/hrorg/create')
      },
      async getList() {
        // console.log('getList')
        var vm = this
        const { sortBy, sortDesc, page, itemsPerPage } = this.tableOpt
        vm.listQuery.page = page
        vm.listQuery.limit = itemsPerPage
        vm.listQuery.limit = itemsPerPage == -1 ? vm.totalData : itemsPerPage
        // console.log('listQuery: ',vm.listQuery)
        // get total data
        vm.listLoading = true
        countHROrganization(vm.listQuery).then(response => {
          var data = response.data

          vm.totalData = parseInt(data)
          // console.log(vm.totalData)
          vm.listLoading = false
        })
        // get list of data
        vm.listLoading = true

        getHROrganization(vm.listQuery).then(response => {
          // console.log(response.data)
          var array = response.data
          array.forEach(element => {
            element['sname'] = '-'
            if(element.parent){
              if(element.parent.structure_name){
                element['sname'] = element.parent.structure_name 
              }
            }
          })


          if (sortBy && sortDesc && sortBy.length === 1 && sortDesc.length === 1) {
            array = array.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }

          vm.listData = array
          vm.listLoading = false
        })
      },
      delData (id) {
        const vm = this
        vm.idDel = id
        vm.delConfirm = true
      },
      doDeleteData () {
        const vm = this
        vm.delConfirm = false
        if (vm.idDel == 0) {
          return false
        }
        vm.isLoading = true
        // console.log('deleteData: ',vm.idDel)
        deleteHROrganization(vm.idDel).then(response => {
          var data = response.data
          // console.log(data)
          vm.isLoading = false
          vm.getList()
          vm.toast = {
            show: true, color:'green', text: 'Data berhasil terhapus.', timeout: 2000
          }
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
           vm.toast = {
            show: true, color:'red', text: 'Data GAGAL terhapus, silakan dicoba kembali.', timeout: 2000
          }
        })
      },

    }
  }
</script>
